import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/threads_te_2.jpg";
import OrganicStandard from "../images/ocs-logos2x.png";
import RecycledStandard from "../images/logos-the-recycled-claim-standard.png";
import GlobalStandard from "../images/logos-the-global-recycled-standard.png";
import ResponsibleStandard from "../images/logos-the-responsible-down-standard.png";
import WoolStandard from "../images/rws-logo2x.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("sustainability.textileexchange-x-foursource.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.textileexchange-x-foursource.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("sustainability.textileexchange-x-foursource.imgAlt"),
        smallHeight: true,
      },
    },
    firstText: [
      {
        text: t("sustainability.textileexchange-x-foursource.textileIndustry"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    whatIs: {
      title: {
        text: t("sustainability.textileexchange-x-foursource.whatIs"),
        colorClass: "colorDark",
      },
      text: {
        text: t("sustainability.textileexchange-x-foursource.teStandard"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    whatIsText: [
      {
        text: t("sustainability.textileexchange-x-foursource.teMaterials"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    titleStandards: {
      title: {
        text: t("sustainability.textileexchange-x-foursource.teStandards"),
        colorClass: "colorDark",
      },
      separator: true,
    },
    textStandards: [
      {
        text: t("sustainability.textileexchange-x-foursource.teStandardsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    TEButton: {
      text: t("sustainability.textileexchange-x-foursource.learnMore"),
      url: "https://textileexchange.org/",
      type: "mainColor",
    },
    resultsButton: {
      text: t("sustainability.textileexchange-x-foursource.seeMore"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    standardsList: [
      {
        img: {
          src: OrganicStandard,
          alt: "aaaa",
        },
        title: {
          text: t("sustainability.textileexchange-x-foursource.organicStandardTitle"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font17 fontWeightBold",
        },
        text: {
          text: t("sustainability.textileexchange-x-foursource.organicStandardText"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: RecycledStandard,
          alt: "aaaa",
        },
        title: {
          text: t("sustainability.textileexchange-x-foursource.recycledStandardTitle"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font17 fontWeightBold",
        },
        text: {
          text: t("sustainability.textileexchange-x-foursource.recycledStandardText"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: GlobalStandard,
          alt: "aaaa",
        },
        title: {
          text: t("sustainability.textileexchange-x-foursource.globalStandardTitle"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font17 fontWeightBold",
        },
        text: {
          text: t("sustainability.textileexchange-x-foursource.globalStandardText"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: ResponsibleStandard,
          alt: "aaaa",
        },
        title: {
          text: t("sustainability.textileexchange-x-foursource.responsibleStandardTitle"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font17 fontWeightBold",
        },
        text: {
          text: t("sustainability.textileexchange-x-foursource.responsibleStandardText"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font14",
        },
      },
      {
        img: {
          src: WoolStandard,
          alt: "aaaa",
        },
        title: {
          text: t("sustainability.textileexchange-x-foursource.woolStandardTitle"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font17 fontWeightBold",
        },
        text: {
          text: t("sustainability.textileexchange-x-foursource.woolStandardText"),
          colorClass: "colorDark",
          textType: "paragraph",
          extraClass: "font14",
        },
      },
    ],
  };
};

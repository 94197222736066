import CheckIcon from "../../images/icon/check.svg";

export const searchTexts = (t) => {
  return {
    searchText: {
      subtitle: {
        text: "",
        colorClass: "colorDark",
      },
      separator: true,
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
      customPBText: true,
      noPadding: true,
      item: {
        text: [
          t("sustainability.gots-x-foursource.accessNetwork"),
          t("sustainability.gots-x-foursource.selectProfiles"),
          t("sustainability.gots-x-foursource.identifyBest"),
          t("sustainability.gots-x-foursource.filterFactories"),
        ],
      },
    },
  };
};

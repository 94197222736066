/**
 * Foursource page
 * https://foursource.com/sustainability/textileexchange-x-foursource
 */

import * as React from "react";
import { withTrans } from "../../i18n/withTrans";
import Utm from "../../components/_global/utm";
import { pageTexts } from "../../content/textileexchange-x-foursource";
import { searchTexts } from "../../content/pages/searchCerts";
import { StaticImage } from "gatsby-plugin-image";

// components
import Layout from "../../components/layout";
import TextImgBackgroundFull from "../../components/textImgBackgroundFull";
import Text from "../../components/text";
import TitleSeparator from "../../components/titleSeparator";
import Button from "../../components/_partials/_button";
import BulletsSection from "../../components/bulletsSection";

class textileExchangePage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.searchTexts = searchTexts(this.t);
    this.searchTexts.searchText.subtitle.text = this.t("sustainability.textileexchange-x-foursource.searchingTE");
  }

  render() {
    return (
      <>
        <Layout pageName="sustainability.textileexchange-x-foursource" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-50">
            <Text text={this.text.firstText}></Text>
          </div>
          <div className="paddingSidesBig backgroundGrey topBottomDiagonal pt-30">
            <div className="pt-100 pb-100">
              <TitleSeparator info={this.text.whatIs}></TitleSeparator>
              <Text text={this.text.whatIsText}></Text>
              <div className="imgCenter text-center">
                <StaticImage
                  src="../../images/textileexchange-logo.png"
                  alt={this.t("sustainability.textileexchange-x-foursource.imgAlt")}
                  placeholder="blurred"
                />
              </div>
              <div className="pt-10">
                <TitleSeparator info={this.text.titleStandards}></TitleSeparator>
                <Text text={this.text.textStandards}></Text>
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 m-0">
                  {this.text.standardsList.map((item, i) => (
                    <div key={i} className="pt-15 pr-10 pl-10">
                      <div className="imgCenter text-center">
                        <img className="w-100 pl-20 pr-20" src={item.img.src} alt={item.img.alt} />
                      </div>
                      <div className="pt-20 pb-30">
                        <Text text={[item.title]}></Text>
                        <Text text={[item.text]}></Text>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row justify-content-center pb-20">
                  <Button cta={this.text.TEButton}></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pt-40">
            <BulletsSection info={this.searchTexts.searchText}></BulletsSection>
            <a href={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)} target="_blank" rel="noreferrer">
            <div className="d-flex justify-content-center">
              <StaticImage
                src="../../images/textileexchange-04022020.png"
                alt={this.t("sustainability.textileexchange-x-foursource.platformAlt")}
                className="h-100"
                placeholder="blurred"
              />
              </div>
            </a>
            <div className="row justify-content-center pb-100">
              <Button cta={this.text.resultsButton}></Button>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(textileExchangePage);
